<template>
  <div style="display: flex; justify-content: space-between">
    {{scope.row.scale.forecast_percent|numberFormat}}

    <el-tooltip
      v-if="showError"
      :content="'Выполнение плана ниже ' + settings.card_element_forecast_percent_threshold + '%'"
      placement="top-start"
    >
      <i class="fas fa-exclamation-circle forecast-percent-error"></i>
    </el-tooltip>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {mapGetters} from "vuex";

export default {
  name: 'element-forecast-percent-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
    showError(){
      if( typeof this.scope.row.scale.forecast_percent !==  'number' ){
        return false;
      }
      return this.scope.row.scale.forecast_percent < this.settings.card_element_forecast_percent_threshold;
    }
  },

  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.forecast-percent-error{
  color: $--color-danger;
  margin-top:1px;
  font-size: 20px;
}
</style>
